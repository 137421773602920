import request from '@/utils/request'

export const getProductList = (params) => {
    return request({
        url: '/market/product/list',
        params
    })
}

export const getTabList = (params) => {
    return request({
        url: '/system/dict/type/list',
        params
    })
}

export const getTabData = (params) => {
    return request({
        url: '/system/dict/data/list',
        params
    })
}

// 获取手机验证码
export const getCode = (params) => {
    return request({
        url: '/market/message/sendMessage?phone='+params.phone,
    })
}

// 新增需求
export const addDemand = (data) => {
    return request({
        url: '/market/demand/add',
        method: 'post',
        data
    })
}
// 提交留言
export const addMessageDemand = (data) => {
    return request({
        url: '/market/demand/addTsMarketDemand',
        method: 'post',
        data
    })
}

// 获取甄选官
export const getCustomer = (params) => {
    return request({
        url: '/market/cio/list',
        params
    })
}

export const getBannerList = (params) => {
    return request({
        url: '/market/carousel/list',
        params
    })
}


export const getSupplierList = (params) => {
    return request({
        url: '/market/supplier/list',
        params
    })
}


// 统计数量
export const getCount = (params) => {
    return request({
        url: '/market/product/selectCountByType',
        params
    })
}

// 统计浏览量
export const getPageView = (data) => {
    return request({
        url: '/market/product/updatePageView?id='+data.id,
        method: 'post',
    })
}